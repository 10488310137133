import { useState, useEffect, useCallback } from 'react'

import { BUILD_ENV } from 'gatsby-env-variables'

import { useLead, useProposal } from '@itau-loans-www/shopping/src/hooks'
import { http } from '@itau-loans-www/shopping/src/services'
import analytics from '@mobi/libraries/analytics'
import { goTo } from 'utils'

import { DDPageError } from 'utils/datadog/events'

import services from './services'

const useName = ({ hasDocument }) => {
  const { cleanProduct } = useProposal()
  const [isButtonLoading, setButtonLoading] = useState(false)

  const { changeOffers, changeLeadData, leadData } = useLead()

  const isProd = BUILD_ENV === 'production'

  const cleanOlderProposalData = useCallback(() => {
    services
      .getFullName()
      .then((data) => {
        changeLeadData({ previousStep: data?.previous_step })
      })
      .catch((err) => {
        const errorPayload = {
          error: err?.message,
          requestURL: err?.request?.responseURL
        }

        DDPageError({
          name: 'useName | getFullName',
          payload: errorPayload
        })
      })

    if (hasDocument) {
      return
    }

    http.cleanAuth()
    cleanProduct()
    changeOffers({
      hero: undefined,
      others: undefined,
      desiredLoanValue: undefined
    })
  }, [changeOffers, changeLeadData, cleanProduct, hasDocument])

  useEffect(() => {
    cleanOlderProposalData()
  }, [cleanOlderProposalData])

  useEffect(() => {
    analytics.track('pageLoad', {
      currentPage: 'formulario-dados-complementares-nome',
      customLayer: { variant: leadData?.vwoVariant }
    })
  }, [])

  const onSubmit = async ({ name }) => {
    setButtonLoading(true)

    analytics.track('buttonClick', {
      currentPage: 'formulario-dados-complementares-nome',
      detail: 'click-step-basic'
    })

    return services
      .putFullName({ name })
      .then(({ next_step }) => {
        changeLeadData({
          name
        })
        goTo(next_step)
      })
      .catch((err) => {
        const errorPayload = {
          error: err?.message,
          requestURL: err?.request?.responseURL
        }

        DDPageError({
          name: 'useName | onSubmit',
          payload: errorPayload
        })
      })
      .finally(() => setButtonLoading(false))
  }

  return {
    onSubmit,
    isButtonLoading,
    isProd
  }
}

export default useName
